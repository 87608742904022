code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    line-height: 1.6;
    font-size: 18px;
    color: #444;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    flex: 1 0;
}

h1, h2, h3 {
    line-height: 1.2
}

@media (prefers-color-scheme: dark) {
    body {
        color: #ccc;
        background: #202124;
    }
}
