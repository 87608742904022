.sidebar {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1em;
  .comment {
    color: #666;
  }
  .input {
    display: flex;
    flex-direction: row;
    label {
      margin-right: 1.5em;
      &:after {
        content: ":";
      }
    }
    input {
      flex: 1 0;
    }
  }
  textarea {
    flex: 1;
  }
  .output {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .code {
      overflow: scroll;
    }
  }
}
